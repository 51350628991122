import styled from 'styled-components';
const EntryFactsStyle = styled.div`
   text-align: center;
    h2 {
      font-size: 1.5em;
      color: white;
    } 
    h3 {
      font-size: 4em;
      color: white;
    }
`;

export default EntryFactsStyle