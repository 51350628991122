import React, {Profiler } from 'react';
import Navbar from '../components/App/Navbar';
import Footer from '../components/App/Footer';
import Layout from '../components/App/Layout';
import Banner from '../components/Index/Banner';
import OurServices from '../components/Index/OurServices';
import StartProject from '../components/Common/StartProject';
import FeatureServices from '../components/Index/FeaturedServices';
import Entryfacts from '../components/Index/EntryFacts';
import ServiceProviders from '../components/Index/ServiceProviders';
import SEO from '../components/App/SEO';
import topImage from '../assets/images/top-banner-op-image.png';

const Home = () => {
  return (
    <Layout>
      <SEO
        title="SNSキャンペーンツール"
        banner={topImage}
        desc={"Twitterのフォロー＆リツイートからLINEの友達登録などのSNSキャンペーンや、レシートキャンペーンも一括で管理キャンペーンツールです。インスタントウィン、抽選、ハッシュタグ機能が充実。"}
        pathname={"/"}
        type={"Article"}
        shortName="SNSキャンペーンツール"
        createdAt="2021-03-02"
      />
      <Navbar />
      <Banner />
      {/*<CommingSoon />*/}
      {/*<Partner />*/}
      <FeatureServices />
      <OurServices />
      {/*<Entryfacts />*/}
      <StartProject />
      {/*<Faq />*/}
      <ServiceProviders />
      <Footer />
    </Layout>
  )
};

export default Home