import React from 'react';
import {StaticImage} from 'gatsby-plugin-image';

const OurServices = () => {
    return (
        <React.Fragment>
            <div className="section-title pt-100 pb-70">
                    <span className="sub-title">
                        <StaticImage src="../../assets/images/star-icon.png" alt="features" />
                        Our Solutions
                    </span>
                <h2>リタッチのメリット</h2>
                {/*<p style={{textAlign: 'center',maxWidth:'700px'}}>新しいサービスが登場する度に、システム開発のコストがかかったり、サービス規約、ユーザー層、応募者の管理などの把握でマーケティング担当者の業務は膨れ上がります。*/}
                {/*    <br/>リタッチを使えば応募、管理、当選まで一括して管理できます！</p>*/}
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <StaticImage src="../../assets/images/useCase/social-networking-service.png" alt="service"/>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content">
                                    {/*<span className="sub-title">*/}
                                    {/*    <StaticImage src={starIcon} alt="icon" /> 多機能*/}
                                    {/*</span>*/}

                                    <h2>60以上の応募方法を提供</h2>
                                    <p>Twitter,Instagram,LINE,Facebookから、YouTube,Twitch,Spotify等のプラットフォームとも連携したキャンペーンを開催できます。従来のマストバイ、フォトコンテスト、アンケートも可能です。<br/>
                                    </p>
                                    {/*<Link to="/examples" className="default-btn">*/}
                                    {/*    <i className="flaticon-right"></i>もっと詳しく<span></span>*/}
                                    {/*</Link>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content">
                                    {/*<span className="sub-title">*/}
                                    {/*    <StaticImage src={starIcon} alt="icon" /> 拡散力*/}
                                    {/*</span>*/}

                                    <h2>キャンペーンの集客力</h2>
                                    <p>キャンペーンの参加者にシェアしてもらい、友達や家族に参加を促進する機能があります。応募フォームはHTML上のどこにでも設置できるので、ホームページ、ECサイト、WEBメディア、LINE等で開催できます。</p>
                                    {/*<Link to="/instantwin" className="default-btn">*/}
                                    {/*    <i className="flaticon-right"></i>もっと詳しく<span></span>*/}
                                    {/*</Link>*/}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <StaticImage src="../../assets/images/useCase/viral-share.png" alt="service"/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                {/*<div style={{width:'450px',margin:'auto'}}>*/}
                                <StaticImage src="../../assets/images/useCase/entrants.png" alt="service" />
                                {/*</div>*/}
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content">
                                    {/*<span className="sub-title">*/}
                                    {/*    <StaticImage src={starIcon} alt="icon" /> 一括管理*/}
                                    {/*</span>*/}

                                    <h2>管理コストの削減</h2>
                                    <p>応募者をExcelやメモ帳で管理していませんか？リタッチでは１つの応募を画面で管理でき応募の無効も１クリック。応募者をメールアドレスで一元管理し、過去に参加したキャンペーンやSNSアカウントを見ることができます。</p>
                                    {/*<Link to="/gallery" className="default-btn">*/}
                                    {/*    <i className="flaticon-right"></i>もっと詳しく<span></span>*/}
                                    {/*</Link>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content">
                                    {/*<span className="sub-title">*/}
                                    {/*    <Image src={starIcon} alt="icon" style={{width:'450px'}} /> リードタイム*/}
                                    {/*</span>*/}

                                    <h2>導入時間の短縮</h2>
                                    <p>サイトからユーザー登録後に管理画面で応募フォームを作成して頂くだけです。リタッチは１０分もあればキャンペーンを作成できますので、機会損失になりません。</p>
                                    {/*<Link to="/instantwin" className="default-btn">*/}
                                    {/*    <i className="flaticon-right"></i>もっと詳しく<span></span>*/}
                                    {/*</Link>*/}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                {/*<div style={{width:'450px',margin:'auto'}}>*/}
                                <StaticImage src="../../assets/images/useCase/readtime.png" alt="service"/>
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <StaticImage src="../../assets/images/useCase/pricing.png" alt="service" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content">
                                    {/*<span className="sub-title">*/}
                                    {/*    <Image src={starIcon} alt="icon" /> 料金設定*/}
                                    {/*</span>*/}
                                    <h2>キャンペーンコストの削減</h2>
                                    <p>キャンペーンするSNSを増やしたり、応募方法を変更しても追加料金は発生せず、最低3カ月使用といった最低契約期間もありません。リタッチは初期費用は無料で、月額2,980円から開始できます。</p>
                                    {/*<Link to="/pricing" className="default-btn">*/}
                                    {/*    <i className="flaticon-right"></i>もっと詳しく<span></span>*/}
                                    {/*</Link>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content">
                                    {/*<span className="sub-title">*/}
                                    {/*    <StaticImage src={starIcon} alt="icon"  /> テスト実施*/}
                                    {/*</span>*/}

                                    <h2>テスト導入にも最適</h2>
                                    <p>キャンペーンの参加者がどのSNSやサービスを使っているかを確認できるため、予算をかけて大きなキャンペーンを行う前のテスト導入としても最適です。</p>
                                    {/*<Link to="/instantwin" className="default-btn">*/}
                                    {/*    <i className="flaticon-right"></i>もっと詳しく<span></span>*/}
                                    {/*</Link>*/}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <StaticImage src="../../assets/images/useCase/test-case.png" alt="service" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

export default OurServices;