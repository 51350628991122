import React from 'react';
import {StaticImage} from 'gatsby-plugin-image';

const HowItWork = () => {
    return (
        <section className="process-area ptb-100 bg-fafafb">
            <div className="container">
                <div className="section-title" style={{maxWidth: '100%'}}>
                    <h2>多くのプラットフォームに対応</h2>
                    <p style={{maxWidth: "100%"}}>Twitter、Instagram、Facebookなどのメジャーなサービスだけでなく、<br />音楽ストリーミングのAppleMusicやSpotify,LINEやTwitCastingなど日本で人気のあるサービスにも対応</p>
                </div>

                <div className="row align-items-center m-0">
                    <div className="col-12">
                        <div className="process-image">
                                <StaticImage src="../../assets/images/services/social-icons.png" alt="banner" />
                                <StaticImage src="../../assets/images/services/feedback.png" alt="banner" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default HowItWork