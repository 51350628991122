import React, {useState} from 'react';
import {StaticImage} from 'gatsby-plugin-image';
import Wrapper from './FeaturedServices.style';
import Drawer from '../../components/UiComponent/drawer';
import 'react-modern-drawer/dist/index.css'

const FeaturedServices = () => {
    const [visible, setVisible] = useState(false);
    const [drawerType, setDrawerType] = useState('widget');
    const [id, setId] = useState(null);
    const [drawerTitle, setDrawerTitle] = useState(null);
    const showDrawer = (id, title, type='widget') => {
        setVisible(true);
        setId(id);
        setDrawerTitle(title)
        setDrawerType(type)
    };
    return (
      <Wrapper>
        <section className="services-area pt-100 pb-100">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <StaticImage src="../../assets/images/star-icon.png" alt="features" />
                        Our Services
                    </span>
                    <h2>リタッチが提供するサービス</h2>
                    {/*<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>*/}
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <StaticImage src="../../assets/images/services/campaign.png" alt="features" style={{width:'inherit'}} />
                            </div>
                            <h3>
                                    キャンペーン
                            </h3>
                            <p>60以上の応募方法を組み合わせた応募フォームで、ホームページ、ECサイト、WEBメディア、どこでも開催できます。</p>
                            {/*<Link to="/campaign" className="read-more-btn">*/}
                            {/*    詳しくはこちら*/}
                            {/*    <i className="flaticon-right"></i>*/}
                            {/*</Link>*/}
                            {/*<button type="button" className="btn btn-primary" onClick={()=>showDrawer('6c96363eed8b02876a','キャンペーンサンプル')}>サンプルを見る</button>*/}
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <StaticImage src="../../assets/images/services/instantwin.png" alt="features" style={{width:'inherit'}}/>
                            </div>
                            <h3>
                                    インスタントウィン
                            </h3>
                            <p>応募フォームから応募した参加者に、クーポンコード、電子ファイル等のデジタル賞品をリアルタイムで提供できます。</p>
                            {/*<button type="button" className="btn btn-primary"  onClick={()=>showDrawer('ad1131cc1129c8ca26','インスタントウィンサンプル')}>サンプルを見る</button>*/}
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <StaticImage src="../../assets/images/services/gallery.png" alt="features" style={{width:'inherit'}}/>
                            </div>
                            <h3>
                                    ギャラリー
                            </h3>
 
                            <p>Twitter,Instagramに投稿されたユーザーコンテンツをリタッチが自動収集します。<br/></p>
                            {/*<button type="button" className="btn btn-primary"  onClick={()=>showDrawer('44wfxikmbxr5hm', '#コーヒーミル' , 'gallery')} >サンプルを見る</button>*/}
                        </div>
                    </div>
                </div>
            </div>
        </section>
          <Drawer visible={visible} id={id} drawerTitle={drawerTitle} setVisible={setVisible} type={drawerType}/>

      </Wrapper>
    )
};

export default FeaturedServices