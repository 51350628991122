import React from 'react';
import {Link} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';

const Banner = () => {
    return (
        <div className="it-services-banner">
            <div className="container" style={{maxWidth: '1400px'}}>
                <div className="row align-items-center">
                    <div className="col-lg-5 col-md-10">
                        <div className="main-banner-content" style={{paddingRight: '1em'}}>
                            <div style={{maxWidth: '530px',margin:'auto'}}>
                                <h1 style={{fontSize:'56px'}}>10分でつくれる<br/>SNSキャンペーン</h1>
                                <p>Twitter,Instagram,LINE,マストバイ,アンケートなど、多様なキャンペーンの応募から抽選までを一括管理します！</p>

                                {/*<div className="btn-box">*/}
                                {/*    <Link href="/signup" className="default-btn" style={{fontSize:'20px',width:'300px',paddingTop:'15px',paddingBottom:'15px'}}>*/}
                                {/*        <i className="flaticon-right"></i>*/}
                                {/*        無料で今すぐ試す <span ></span>*/}
                                {/*    </Link>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-7 col-md-14" >
                            <div className="main-banner-image" style={{paddingLeft: '1.5em'}}>
                                <StaticImage src="../../assets/images/banner.png" alt="banner"/>
                            </div>
                    </div>
                </div>

            </div>

        </div>
    )
};

export default Banner